

























import Vue from 'vue'
import ProfileBillingAvailableSubscriptionsPlanInfo from './ProfileBillingAvailableSubscriptionsPlanInfo.vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ProfileBillingAvailableSubscriptions',
  components: { ProfileBillingAvailableSubscriptionsPlanInfo },
  data () {
    return {
      selectedPeriod: {},
      options: []
    }
  },
  computed: {
    ...mapGetters('plans', ['getPlans'])
  }
})
