import IPlanDetails from '@/models/IPlanDetails'
import IPlanPeriod from '@/models/IPlanPeriod'

function toFixedPrice (price: number): number {
  return price !== Math.round(price)
    ? parseFloat(price.toFixed(2)) // 2.11111111 => 2.11
    : price
}

export function optionsPlanConverter (plan: IPlanDetails): IPlanPeriod[] {
  const arr = []
  if (plan.monthly_price > 0) {
    arr.push({
      name: 'Monthly',
      price: toFixedPrice(plan.monthly_price),
      price_with_discount: toFixedPrice(plan.monthly_price_with_discount),
      discount: plan.monthly_discount,
      frequency: 1,
      discount_first_payment: plan.monthly_discount_first_payment,
      discount_upgrade: plan.monthly_discount_upgrade,
      discount_type: plan.monthly_discount_upgrade ? 'Upgrade for:' : 'First month:'
    })
  }
  if (plan.annual_price > 0) {
    arr.push({
      name: 'Annual',
      price: toFixedPrice(plan.annual_price),
      price_with_discount: toFixedPrice(plan.annual_price_with_discount),
      discount: plan.annual_discount,
      frequency: 12,
      discount_first_payment: plan.annual_discount_first_payment,
      discount_upgrade: plan.annual_discount_upgrade,
      discount_type: plan.annual_discount_upgrade ? 'Upgrade for:' : 'First month:'
    })
  }
  return arr
}

export function defaultPeriod (plan: IPlanDetails): IPlanPeriod {
  return {
    name: 'Monthly',
    price: toFixedPrice(plan.monthly_price),
    price_with_discount: toFixedPrice(plan.monthly_price_with_discount),
    discount: plan.monthly_discount,
    frequency: 1,
    discount_first_payment: plan.monthly_discount_first_payment,
    discount_upgrade: plan.monthly_discount_upgrade,
    discount_type: ''
  }
}
